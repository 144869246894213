<template>
  <div class="module-shuxing">
    <div class="module-first">
      <el-button type="primary" size="mini" plain @click="importBatch">批量引用</el-button>
      <el-button type="success" size="mini" @click="listSave">保存商品属性</el-button>
    </div>
    <vxe-grid v-bind="tableOptions" :data="selfList">
      <template #default_dict_name="{ row }">
        <span v-if="row.id">{{ row.dict_name }}</span>
        <el-input v-else size="mini" clearable v-model="row.dict_name" @blur="inputBlur(row)" />
      </template>
      <template #default_operate="{ rowIndex }">
        <span class="iconfont icon-add" title="增行" @click="itemAdd(rowIndex)" />
        <span class="iconfont icon-delete" title="删行" @click="itemDelete(rowIndex)" />
      </template>
    </vxe-grid>
    <PopupImport
      v-if="isShowPopupImport"
      :show.sync="isShowPopupImport"
      :obj="obj"
      @success="importConfirm"
    />
  </div>
</template>

<script>
import PopupImport from './popup-import-shuxing'
export default {
  components: {
    PopupImport
  },
  props: {
    list: {
      type: Array,
      required: true
    },
    obj: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selfList: [
        {
          dict_name: ''
        }
      ],
      tableOptions: {
        size: 'mini',
        border: true,
        'highlight-hover-row': true,
        columns: [
          {
            title: '商品属性名称(表头字段名称)',
            width: 201,
            slots: { default: 'default_dict_name' }
          },
          { title: 'id', field: 'id', width: 102 },
          { title: '操作', align: 'right', slots: { default: 'default_operate' } }
        ]
      },
      isShowPopupImport: false
    }
  },
  watch: {
    list: {
      handler(val) {
        if (val.length) {
          if (JSON.stringify(this.selfList) !== JSON.stringify(val)) {
            this.selfList = this.$util.deepCopy(val)
          }
        } else {
          this.selfList = [
            {
              dict_name: ''
            }
          ]
        }
      },
      deep: true,
      immediate: true
    },
    selfList: {
      handler(val) {
        if (JSON.stringify(this.list) !== JSON.stringify(val)) {
          this.$emit('update:list', this.$util.deepCopy(val))
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    // 项-新增
    itemAdd(index) {
      this.selfList.splice(index + 1, 0, {
        dict_name: ''
      })
    },
    // 项-删除
    async itemDelete(index) {
      let id = this.selfList[index].id
      let isFail = false
      if (id) {
        await this.$api({
          method: 'get',
          url: '/admin/gcvip/dict/dropColumn',
          params: {
            column_data_id: this.obj.id,
            dict_name: this.selfList[index].dict_name
          }
        })
          .then((res) => {
            if (res.data.state === 'ok') {
              this.listSave()
            } else {
              this.$message.error(res.data.msg)
              isFail = true
            }
          })
          .catch((err) => {
            this.$message.error(err.data.msg)
            isFail = true
          })
      }
      if (isFail) return
      if (this.selfList.length > 1) {
        this.selfList.splice(index, 1)
      } else {
        this.selfList[index].dict_name = ''
        this.selfList[index].id = ''
      }
    },
    // 引用-批量
    importBatch() {
      this.isShowPopupImport = true
    },
    // 引用-确认
    importConfirm(importList) {
      let result = []
      importList.forEach((importItem) => {
        let isRepeat = this.selfList.some((selfItem) => {
          return selfItem.dict_name === importItem.dict_name
        })
        if (!isRepeat) {
          result.push({
            dict_name: importItem.dict_name
          })
        }
      })
      if (result.length) {
        let effectList = this.selfList.filter((item) => {
          return item.dict_name
        })
        this.selfList = [...effectList, ...result]
      }
      this.listSave()
    },
    // 输入框-失焦
    inputBlur(row) {
      if (row.dict_name) this.listSave()
    },
    // 列表-保存
    listSave() {
      let effectList = this.selfList.filter((item) => {
        return item.dict_name
      })
      let pushList = effectList.map((item) => {
        return {
          ...item,
          column_data_id: this.obj.id
        }
      })
      this.$api({
        method: 'post',
        url: '/admin/gcvip/dict/saveOrUpdateColumn',
        data: {
          list: pushList
        }
      }).then((res) => {
        if (res.data.state === 'ok') {
          this.$message.success('保存成功')
          this.$emit('success')
        } else {
          this.$message.error(res.data.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.module-shuxing {
  .module-first {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 28px;
    margin-bottom: 5px;
  }
}
</style>
